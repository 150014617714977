import styled from "styled-components/macro";
import { device } from "../components/Helpers";

export const EdetailCultureConversionStyle = styled.main`
  position: relative;
  margin: 12.5rem auto 0;
  @media ${device.mobile} {
	  padding-top: 5rem !important;
  }
  .modal-content-wrap-wrap {
    @media ${device.mobile} {
		width: 33.33% !important;
		margin-top: 0 !important;
		.image-modal {
			height: auto !important;
		}
    }
  }
  #culture-conversion{
    margin-top: 15px;
    margin-bottom: 35px;

    @media ${device.tablet} { margin-top:4rem; }
    @media ${device.desktop_lg} { margin-top: 15px;}
    @media ${device.mobile} {
      font-size: 16px; 
      margin-top: 0;
      margin-bottom: 3.167rem;
    }

    &> h1 br {
      @media screen and (min-width: 768px) {
        display: none;
      }
    }

    &> p {
      @media ${device.mobile} {
        margin-top: -1.5rem;
      }
    }

    h2 {
      @media ${device.desktop}{
      font-size:1.188rem;
       }
     }
  }

  #tabbing-through {
    @media ${device.mobile} {
      z-index: ${(props) => (props.isZoomed ? "-1" : "3")} !important;
      width:96% !important;
    }
  }

  section {
    margin:0 ;
  }

  .key {
    color: black;
  }

  .patient-selection, #baseline, #summary-of-therapy {
    margin-top:0 !important;
    padding-top:2.5rem;
    @media ${device.desktop_xlg}{
      padding-top:1.5rem;
       }
       @media ${device.mobile}{
        padding-top:8.5rem;
       }
  }



  #cc-chart-container {
    position: relative;
    margin-bottom: -1.5rem;
    margin-top: 0rem;

    #chart-container {
      display: flex;
      justify-content: space-between;
      padding: 0 1.5rem;
      margin-bottom: 2rem;

      #primary-endpoint-popup-info-modal-trigger,
      #secondary-endpoint-popup-info-modal-trigger {
        z-index: 2;
        top: 24px;

        @media ${device.tablet}{
          width:85%;
          left:0;
        }
      }

      #primary-endpoint-popup {
        position: relative;

        &::after {
          position: absolute;
          content: none;
          background-color: white;
          bottom: 0;
          left: 42%;
          width: 335px;
          height: 5px;
          z-index: 1;
        }
        &::before {
          content: "";
          border-left: 2px solid #e35205;
          border-right: 2px solid #e35205;
          border-top-left-radius: 0.35rem;
          border-top-right-radius: 0.35rem;
          border-top: 2px solid #e35205;
          height: 1.75rem;
          left: -8%;
          margin-top: 1.75rem;
          position: absolute;
          top: 43px;
          width: 210%;
          z-index: 1;
        }
      }

      #secondary-endpoint-popup {
        position: relative;

        &::after {
          position: absolute;
          content: none;
          background-color: white;
          bottom: 0;
          width: 365px;
          height: 5px;
          left: -14%;
          z-index: 1;
        }
        &::before {
          content: "";
          border-left: 2px solid #e35205;
          border-right: 2px solid #e35205;
          border-top-left-radius: 0.35rem;
          border-top-right-radius: 0.35rem;
          border-top: 2px solid #e35205;
          height: 1.75rem;
          left: -28%;
          margin-top: 1.75rem;
          position: absolute;
          top: 43px;
          width: 135%;
          z-index: 1;
        }
      }

      & > .md-container:nth-of-type(1) {
        &::before {
          margin-left: -2rem;
          width: 39.45rem;
        }

        & > a {
          padding-left: 1rem;
          padding-right: 3.5rem;
          margin-left: 8rem;
        }
      }

      & > .md-container:last-of-type {
        &::before {
          margin-left: 6.85rem;
          width: 27.15rem;
        }

        & > a {
          margin-left: 9.5rem;
          padding-left: 2rem;
          padding-right: 3rem;
        }
      }
    }

    #chart-img {
      margin-top: 4rem;
      margin-bottom: 1rem;
      width:100%;
    }

    #chart-keys-content {
      left: 0;
      margin-left: 2.25rem;
      margin-top: 3.15rem;
      position: absolute;
      top: 4.9rem;

      & > p {
        font-size: 0.75rem;
        letter-spacing: 0;
        line-height: 1.85rem;
        position: relative;

        &:nth-of-type(1) {
          color: ${(props) => props.theme.colors.brand_purple_00};
        }

        // &:nth-of-type(1)::before {
        //   background-color: ${(props) => props.theme.colors.brand_purple_00};
        //   margin-top: 0.25rem;
        // }

        &:nth-of-type(2)::before {
          background-color: ${(props) => props.theme.colors.brand_gray_00};
          margin-top: 0.25rem;
        }

        &:nth-of-type(1)::before,
        &:nth-of-type(2)::before {
          content: "";
          border-radius: 0.15rem;
          left: 0;
          margin-left: -1.75rem;
          padding: 0.65rem;
          position: absolute;
          top: 0;
        }

        // &:last-of-type::before {
        //   content: ".....";
        //   color: ${(props) => props.theme.colors.brand_orange_00};
        //   font-size: 1.75rem;
        //   font-weight: lighter;
        //   left: 0;
        //   letter-spacing: -1.5px;
        //   margin-left: -1.7rem;
        //   margin-top: -0.5rem;
        //   padding: 0;
        //   position: absolute;
        //   top: 0;
        // }
      }
    }
  }
  .slick-dots {
    position: relative !important;
    top: 9.438rem;
    @media ${device.desktop_lg} {
      top: 9.438rem;
    }
    @media ${device.desktop_xlg} {
      top: 9.438rem;
    }
    @media ${device.tablet} {
    top:7.438rem;
    }
    @media ${device.mobile} {
      top: 9rem;
    }
    li {
      width: 14px;
    }
  }

  .md-container #culture-conversion {
    & > br {
      @media ${device.mobile} {
        display: none;
      }
    }

    & > h1 > .highlight {
      display: block;
    }

    h1 {
      @media ${device.mobile} {
        margin-bottom: 1.6667rem;
      }
    }
    .tiny {
      @media ${device.mobile} {
        display: none;
      }
    }

    /* .small-btm-buffer {
        margin-bottom: .75rem;
      } */
  }

  #responder-anaylsis-chart-mob-container {
    .innerWrap .content > p {
      @media ${device.mobile} {
        line-height: 1rem;
      }

      &:nth-of-type(1) {
        @media ${device.mobile} {
          color: inherit !important;
        }
      }
    }

    .innerWrap .img-container > img {
      @media ${device.mobile} {
        padding: 0;
      }
    }
  }

  #primary-endpoint-chart-mob-container {
    .img-container > img {
      @media ${device.mobile} {
        padding: 0;
      }
    }
  }

  #percentage-of-patients-chart-mob-container-content {
    .img-container > img {
      @media ${device.mobile} {
        padding: 0 2rem;
      }
    }
  }

  #drugs-in-reg {
    position: relative;

    & > img {
      display: block;
      margin: 2.5rem auto 4rem auto;
    }
  }

  #key-baseline-chart {
    margin: 2rem auto 0;
    max-width: 45rem;
    width: 100%;

    @media ${device.mobile} {
      margin: 0.75rem auto;
    }
    @media ${device.tablet} {
     width:100%;
    }
  }

  #key-baseline-chart-mobile-div {
    width: 100%;
    overflow-x: scroll;

    @media ${device.mobile} {
      margin-bottom: 3.5rem;
    }
  }

  .mobileHide {
    display: block;
    @media ${device.mobile} {
      display: none;
    }
  }
  .mobileShow {
    display: none;
    @media ${device.mobile} {
      display: block;
    }
  }
  .mobileShowInline {
    display: none;
    @media ${device.mobile} {
      display: inline;
    }
  }

  .tabletShow {
    display: none;
    @media ${device.tabletBelow} {
      display: block;
    }
  }

  .tabletHide {
    display: block;
    @media ${device.tabletBelow} {
      display: none;
    }
  }

  .scroll-indication {
    display: none;

    @media ${device.mobile} {
      display: block;
      margin: 0.75rem 0 -0.25rem 0;
    }
  }

  #regimen-key {
    bottom: 0;
    left: 0;
    margin-bottom: 15rem;
    margin-left: 14.3rem;
    position: absolute;

    @media ${device.tablet}{
      margin-bottom: 17rem;
      margin-left: 2.3rem;
    }

    @media ${device.desktop}{
    margin-left: 7.3rem;
    }

    & > p {
      font-size: 0.75rem;
      letter-spacing: 0;
      line-height: 1.85rem;
      position: relative;

      &:nth-of-type(1) {
        color: ${(props) => props.theme.colors.brand_purple_00};
      }

      &:nth-of-type(1)::before {
        background-color: ${(props) => props.theme.colors.brand_purple_00};
        margin-top: 0.25rem;
      }

      &:nth-of-type(2)::before {
        background-color: ${(props) => props.theme.colors.brand_gray_05};
        margin-top: 0.25rem;
      }

      &:nth-of-type(1)::before,
      &:nth-of-type(2)::before {
        content: "";
        border-radius: 0.15rem;
        left: 0;
        margin-left: -1.75rem;
        padding: 0.65rem;
        position: absolute;
        top: 0;
      }
    }
  }

  .study-design-culture {
    margin-top: 9rem;

    @media ${device.desktop_lg} {
      margin-top: 7.5rem;
    }

    @media ${device.desktop_xlg} {
      margin-top: 7.5rem;
    }

    @media ${device.tablet} {
      margin-top: 4.5rem;
    }

    @media ${device.mobile} {
      margin-top: 6rem;
    }

    & > .list-container {
      @media ${device.mobile} {
        margin-bottom: 1.15rem;
      }
    }
  }

  .patient-selection{
    margin-top: -3.5rem;

    @media ${device.desktop_lg} {
      margin-top: -7rem;
    }

    @media ${device.desktop_xlg} {
      margin-top: 0rem ;
    }

    // @media ${device.tablet} {
    //   margin-top: -2.5rem !important ;
    // }

    @media ${device.mobile} {
      margin-top: -1.25rem;
    }
  }

  #study-design-container {
    align-items: flex-start;
    display: flex;
    flex-direction: row;
    margin: 0;

    @media ${device.mobile} {
      flex-direction: column;
      margin: 1.5rem 0;
      margin-bottom: 3rem;
    }
    
    [data-component="callout"] {
      position: relative;
      margin-top: 2rem;
      padding-left: 3rem;
      padding-right: 1rem;
      padding: 3rem;
      width: 177%;
      margin-left: 5%;
      @media ${device.mobile} {
        margin: 3rem auto 0 !important;
        padding: 3rem 1rem;
        width: 100%;
      }

      &.calendar {
        &::after {
          position: absolute;
          content: url(/images/6-month-calendar-icon.svg);
          left: -7%;
          top: 30%;

          @media ${device.mobile} {
            left: 0;
            margin-left: 35%;
            margin-top: -3.25rem;
            top: 0;
            transform-origin: center center;
            transform: translateX(10%) scale(0.75);
          }
        }
      }
    }

    h3 > sup {
      font-size: xx-small;
    }

    .endpoint-container {
      h3 {
        color:${(props) => props.theme.colors.brand_orange_03};
      }
    }

    .endpoint-container > div {
      margin-bottom: 2.5rem;

      @media ${device.desktop_lg} {
       width:33.5rem;
      }
      &:first-child{
        margin-top: 2.5rem;
      }
      & > p {
        padding-right: 4rem;

        @media ${device.mobile} {
          padding-right: 0;
        }
      }
    }
  }

  #ar-experience {
    margin-top:8.5rem;
    @media ${device.desktop_lg} {
      // margin-top:5rem;
    }
    @media ${device.tablet} {
      margin-top:4.5rem;
    }
    @media ${device.mobile} {
      margin-top: 6rem;
    }
    h1 {
      @media ${device.mobile} {
        margin-bottom: 1.6667rem;
      }
    }
  }

  .list-container {
    display: flex;
    margin: 5rem 2rem 3.75rem 2rem;

    @media ${device.mobile} {
      flex-direction: column;
      margin-left: 0;
      margin-right: 0;
      margin-top: 2.6667rem;
    }

    [data-component="callout"] {
      margin: 0;
      position: relative;

      @media ${device.mobile} {
        margin: 2rem auto;
      }

      &:nth-of-type(1) {
        margin-right: 2rem;

        @media ${device.mobile} {
          margin: 1rem auto 4rem auto;
        }
      }

      &.trial::after {
        background-image: url("../images/icon-trials.svg");
        padding: 2.25rem;

        @media ${device.mobile} {
          padding: 3rem;
          margin-left: 37%;
          margin-top: -3.15rem;
        }
      }

      &.form::after {
        background-image: url("../images/icon-form.svg");
        padding: 2.25rem;

        @media ${device.mobile} {
          margin-left: 38%;
          margin-top: -2.85rem;
          padding: 2.75rem;
        }
      }

      &::after {
        content: "";
        background-color: ${(props) => props.theme.colors.white};
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        border: 1rem solid ${(props) => props.theme.colors.white};
        left: 0;
        margin-left: 40%;
        margin-top: -3.35rem;
        position: absolute;
        top: 0;

        @media ${device.mobile} {
          border-top: 0;
          border-bottom: 0;
        }
      }
      h2 {
        margin: 1rem 0;
        z-index: 1;
        @media ${device.mobile} {
          margin: 1.6667rem 0 1rem;
        }
      }

      li {
        margin-bottom: 1rem;
        @media ${device.mobile} {
          font-size: 1.33333rem;
        }
      }
    }
  }

  #see-mac-container {
    display: flex;

    @media ${device.mobile} {
      flex-direction: column;

      img {
        margin: 0 auto;

        @media ${device.mobile} {
          margin-top: 2rem;
        }

        &#lung-purple {
          @media ${device.mobile} {
            margin-top: 0rem;
          }
        }
      }
    }

    #instruction-container {
      @media ${device.mobile} {
        margin: 1.6667rem auto 2rem;
      }

      > div {
        @media ${device.mobile} {
          flex-direction: column-reverse;
        }
      }
    }
  }

  #baseline {
    @media ${device.desktop}{
      margin-top:-3.5rem;
    }
    @media ${device.desktop_lg}{
      margin-top:-7rem;
    }
    @media ${device.desktop_xlg}{
      margin-top:0rem;
    }
    @media ${device.tablet}{
      margin-top:-3rem;
    }
    h1 {
      @media ${device.tablet}{
       line-height:initial;
      }
    }
  }

  #summary-of-therapy {
    @media ${device.desktop}{
      margin-top:-3.5rem;
    }
    @media ${device.desktop_lg}{
      margin-top:-7rem;
    }
    @media ${device.desktop_xlg}{
      margin-top:0rem;
    }
    @media ${device.tablet}{
      margin-top:-3rem;
    }
    h1 {
      @media ${device.tablet}{
       line-height:initial;
      }
    }
  }

  #patient-profiles {
    padding: 0 2.5rem;

    @media ${device.mobile} {
      padding: 0;
    }
    h1 {
      margin-left: -2.5rem;
      line-height: 1.75rem;
      @media ${device.mobile} {
        margin-left: 0;
      }
      span {
        font-size: 1rem;
        color: ${(props) => props.theme.colors.brand_orange_00};
        font-weight: 600;
        @media ${device.mobile} {
          line-height: 1rem;
          display: block;
          margin-bottom: 0;
          position: relative;
          top: 1rem;
        }
      }
    }
  }

  a.btn-2 {
    margin-top: 1.25rem;
  }

  .tiny {
    margin-top: 2.5rem;
  }

  .definitions {
    @media ${device.mobile} {
      margin-top: 5rem;
    }
  }

  .slick-slide {
    @media ${device.mobile} {
      padding: 0 .25rem;
      width: calc(100% / 3) !important;
    }
  }

  .slick-prev,
  .slick-next {
    bottom: -9.375rem;

    @media ${device.desktop_lg} {
      bottom: -9.438rem;
    }

    @media ${device.tablet} {
      // top: 117% ;
    }

    @media ${device.desktop_xlg} {
      // top: 123% ;
    }

    @media ${device.mobile} {
      // top: 99%;
    }
  }


  .slick-prev {
    @media ${device.mobile} {
      left: 0px;
    }
  }

  .slick-list {
    @media ${device.betweenTabletAndDesktop} {
      margin-top: -7rem;
    }

    .slick-track .slick-slide[data-index="0"] {
      margin-top: 8rem;

      @media screen and (min-width: 1200px) {
        margin-top: -1.25rem;
      }

      @media ${device.mobile} {
        margin-top: .5rem;
      }
    }
  }

  .slick-next {
    @media ${device.mobile} {
      right: -0.5rem;
    }
  }


   .slick-arrow {
    &.hide {
      opacity:0;
    }
  }

  .slick-dots {
    position: static;
    width: fit-content;
    margin: -9rem auto 0 auto;
    list-style: none;
    text-align: center;
    &.hide {
     opacity:0;
    }
  }

  div.backward {
    position: relative;
  }

  .transcript {
    margin-top:1.25rem;
  }
  .definitions-section{
    margin-top:0 0;
    line-height:0.875rem;
    letter-spacing: 0.01071em;

    @media ${device.mobile} {
      margin-top:0;
     }
     .definitions {
      @media ${device.mobile} {
        margin-top:0;
       }
     }
  }
}

  .bottom-footnotes {
    margin-top: 12rem !important;
    @media ${device.desktop} {
     margin-top:3.75rem;
    }
    @media ${device.desktop_lg} {
      margin-top:-1.25rem;
     }
    @media ${device.tablet} {
      margin-top:5.5rem;
     }
    @media ${device.desktop_xlg} {
      margin-top:6.75rem;
     }
     @media ${device.mobile} {
      margin-top:4.833rem;
     }
  }

  .modal-content-wrap {
    height: calc(100% - 320px) !important;
    // height: 75%;
    margin-top: 2vw;
    max-width: 71rem;
    overflow: scroll;
    width: 100%;

    @media ${device.desktop} {
      height: 75%;
      margin-top: -3.5vw;
    }

    /* @media ${device.desktop}{
      height: calc(100% - 356px) !important; ;
    } */
  
    @media ${device.mobile}{
      height: calc(100% - 329px) !important; ;
    }

`;
