import React, { useState, useEffect, useRef } from "react";
import { graphql } from "gatsby";
import { isWindow } from "../components/Helpers";
import { EdetailCultureConversionStyle } from "../styles/EdetailCultureConversionStyle";
import { AudioTranscript } from "../components/AudioTranscript";
import { Injector } from "../components/Injector/partials";
import {
	Container,
	ConvertedMarkdown,
	Definitions,
	Footnotes,
	ChartKeysInjector,
	References,
	SeeMacInside,
	TabbingThrough
} from "../components";

import { SectionCarousel } from "../components/Carousels/SectionCarousel";

const EdetailCultureConversion = ({ data }) => {
	const slider = useRef();
	const [isHCP, setHCP] = useState(false);
	const dataQuery = data.allMarkdownRemark.nodes[0],
		componentID = data.allMarkdownRemark.nodes[0].frontmatter.components_id,
		markdownHTML = data.allMarkdownRemark.nodes[0].html,
		audioTracksWithProps = data.allMarkdownRemark.nodes[0].frontmatter.audioTracksWithProps[0],
		arrowText_1 = data.allMarkdownRemark.nodes[0].frontmatter.arrowText_1[0],
		tabComponentItems = data.allMarkdownRemark.nodes[0].frontmatter.tabComponent,
		sectionSlides = data.allMarkdownRemark.nodes[0].frontmatter.sectionSlides,
		isiSnippet = data.allMarkdownRemark.nodes[0].frontmatter.isiSnippet,
		markdownID = `ed-culture-conversion-content`;
	const mobileCheck = () => {
		// return (isWindow && window.innerWidth < 768); 
		return (isWindow && window.screen.width < 768);
	};
	
	const isMobile = mobileCheck();
	const [trackWProps, setTrackWProps] = useState(isMobile ? `defaultMobile` : `default`);
	const [transcriptState, setTranscriptState] = useState(false);
	const [zoom, setZoom] = useState(false);

	useEffect(() => {
		if (isWindow) {
			let isHCP = sessionStorage.getItem("hcp");
			if (isHCP !== "true") {
				setHCP(false);
			} else {
				setHCP(true);
			}
		}
	}, [isHCP, setHCP]);
	const ccTabsSetTrackWProps = (next) => {
		let trackKey = [
			"default",
			"patient",
			"baseline",
			"standard"
		];
		let nextTrackKey = (trackKey[next] === "default" && isMobile) ? "defaultMobile" : trackKey[next];
		setTrackWProps(nextTrackKey);
	}

	const ccSlidesSetTrackWProps = (next) => {
		let trackKey = [
			"default",
			"slide2",
			"slide3"
		];
		let nextTrackKey = (trackKey[next] === "default" && isMobile) ? "defaultMobile" : trackKey[next];
		nextTrackKey = (nextTrackKey === "slide3" && isMobile) ? "slide3Mobile" : nextTrackKey;
		setTrackWProps(nextTrackKey);
	}
	const ccModalSetTrackWProps = (next) => {
		let trackKey = [
			"default",
			"modal1",
			"modal2",
			"standard"
		];
		let nextTrackKey = (trackKey[next] === "default" && isMobile) ? "defaultMobile" : trackKey[next];
		setTrackWProps(nextTrackKey);
	}

	let ccModalTrackKey = [
		"default",
		"modal1",
		"modal2",
		"standard"
	];

	if (isMobile) {
		ccModalTrackKey = [
			"defaultMobile",
			"modal1",
			"modal2",
			"standard"
		];
	}

	// <AudioTranscript id={`audio-transcript-modal1`} transcriptState={transcriptState} setTranscriptState={setTranscriptState} transcriptCopy={audioTracksWithProps[trackWProps].transcript} />


	return (
		<>
			<Container
				markdownID={markdownID}
				componentID={componentID}
				query={dataQuery}
				isiSnippet={isiSnippet}
				trackWProps={audioTracksWithProps[trackWProps]}
				setTrackWProps={setTrackWProps}
				ccModalSetTrackWProps={ccModalSetTrackWProps}
				transcriptState={transcriptState}
				setTranscriptState={setTranscriptState}
				setZoom={setZoom}
				isZoomed={zoom}
				ccModalTrackKey={ccModalTrackKey}
			>
				<EdetailCultureConversionStyle id={`main`} className={`wrapper`} isZoomed={zoom}>
					<TabbingThrough
						id={`tabbing-through`}
						resetSlides={(tabIndex) => {
							if (tabIndex === 0) {
								slider.current.slickGoTo(0);
							}
						}}
						tabs={tabComponentItems}
						setTrackWProps={ccTabsSetTrackWProps}
						zoom={zoom}
						setZoom={setZoom}
						isZoomed={zoom}
						setTranscriptState={setTranscriptState}
					/>
					<SectionCarousel
						className={`culture-conversion-carousel show visible margin`}
						arrowText={arrowText_1.copy}
						arrowTextISI={arrowText_1.isiSnippets}
						markdownID={markdownID}
						slider={slider}
						slidesYouWantToMake={sectionSlides}
						dataOptions={`1`}
						setTrackWProps={ccSlidesSetTrackWProps}
					/>
					<ConvertedMarkdown markdownID={markdownID} mdhtml={markdownHTML} />
					<Footnotes
						isBottom={true}
						footnotes={dataQuery.frontmatter.footnotes}
					/>
					<Definitions
						markdownID={markdownID}
						definitions={dataQuery.frontmatter.definitions}
					/>
					<References
						markdownID={markdownID}
						references={dataQuery.frontmatter.references}
					/>
					<Injector
						container={"cc-slide-1-tran"}
						content={
							<AudioTranscript id={`audio-transcript-modal1`} transcriptState={transcriptState} setTranscriptState={setTranscriptState} transcriptCopy={audioTracksWithProps[trackWProps].transcript} />
						}
					/>
					<Injector
						container={"cc-slide-2-tran"}
						content={
							<AudioTranscript id={`audio-transcript-modal2`} transcriptState={transcriptState} setTranscriptState={setTranscriptState} transcriptCopy={audioTracksWithProps[trackWProps].transcript} />
						}
					/>
					<AudioTranscript id={`audio-transcript-main`} transcriptState={transcriptState} setTranscriptState={setTranscriptState} transcriptCopy={audioTracksWithProps[trackWProps].transcript} />
					{/* <PopupsInjectorFromJsx popups={popups} componentID={componentID} setTrackWProps={ccModalSetTrackWProps} /> */}
					<ChartKeysInjector componentID={componentID} />
					<SeeMacInside componentID={componentID} />
				</EdetailCultureConversionStyle>
			</Container>
		</>
	);
};

export const query = graphql`
{
    allMarkdownRemark(
      filter: {frontmatter: {markdown_id: {eq: "ed-culture-conversion-content"}}}
    ) {
      nodes {
        frontmatter {
          slug
          description
          keywords
          markdown_id
          title
          definitions
          isiSnippet
          footnotes
          references
          audioTracksWithProps {
            default {
              trackID
              isiContent
              src
              title
              transcript
            }
            defaultMobile {
              trackID
              isiContent
              src
              title
              transcript
            }
            modal1 {
              trackID
              isiContent
              src
              title
              transcript
            }
            modal2 {
              trackID
              isiContent
              src
              title
              transcript
            }
            slide2 {
              trackID
              isiContent
              src
              title
              transcript
            }
            slide3 {
              trackID
              isiContent
              src
              title
              transcript
            }
            slide3Mobile {
              trackID
              isiContent
              src
              title
              transcript
            }
            patient {
              trackID
              isiContent
              src
              title
              transcript
            }
            baseline {
              trackID
              isiContent
              src
              title
              transcript
            }
            standard {
              trackID
              isiContent
              src
              title
              transcript
            }
          }
          mobileCharts {
            container
            content
            img
            isiSnippets
          }
          sectionSlides
          components_id
          tabComponent {
            title
            isiSnippets
          }
          arrowText_1 {
            copy
            isiSnippets
          }
        }
        id
        html
      }
    }
  }
`;

export default EdetailCultureConversion;
